import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';

import {getTranslated} from '../../helpers/lang';
import {getPersonByID} from '../../selectors/data';
import './style.scss';

const Image = props => (
	<StaticQuery
		query={graphql`
			{
				images: allFile(
					filter: {
						extension: {regex: "/(jpg)|(jpeg)|(png)/"}
						relativeDirectory: {eq: "pages/company/persons"}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								gatsbyImageData(width: 600, layout: CONSTRAINED)
							}
						}
					}
				}
			}
		`}
		render={data => {
			const image = data.images.edges.find(n => {
				return n.node.relativePath.includes(props.filename);
			});
			if (!image) {
				const placeholderImage = data.images.edges.find(n => {
					return n.node.relativePath.includes('placeholder');
				});

				return (
					<GatsbyImage
						alt={props.alt}
						image={placeholderImage.node.childImageSharp.gatsbyImageData}
						className={'gst-person-image'}
					/>
				);
			}

			return (
				<GatsbyImage
					alt={props.alt}
					image={image.node.childImageSharp.gatsbyImageData}
					className={'gst-person-image'}
				/>
			);
		}}
	/>
);

const Person = ({email}) => {
	const person = getPersonByID(email);
	const text = person.text ? person.text.map(t => <p>{t}</p>) : null;
	return (
		<div className="gst-person">
			<div className="gst-person-image-background">
				<div className="gst-person-image">
					<Image filename={email} />
				</div>
			</div>
			<div className={'gst-person-content'}>
				<div className={'gst-person-title'}>
					<span>{person.name}</span>
					{person.showEmail ? (
						<a className="gst-person-mailto" href={'mailto:' + email}>
							<Icon icon="mail" />
						</a>
					) : null}
				</div>
				{person.position ? (
					<div className={'gst-person-position'}>
						{getTranslated(person.position)}
					</div>
				) : null}
			</div>
		</div>
	);
};

Person.propTypes = {
	email: PropTypes.string.isRequired,
};

export default Person;
