import React from 'react';
import PropTypes from 'prop-types';
import {getTranslated} from '../helpers/lang';
import GridList from './GridList';
import PositionItem from './OpenPositionItem';

const notHiring = {
	cs: 'Všechny pozice jsou obsazené.',
	en:
		"There are no open positions here at the moment. Still really interested in working with us? Get in touch, we'll see how we fit!",
};

const PositionsList = ({data, count, lang}) => {
	const items = data
		.filter(position => position.open)
		.slice(0, count)
		.map(position => (
			<PositionItem id={position.key} key={position.key} lang={lang} />
		));

	return (
		<GridList>
			{items && items.length > 0 ? (
				items
			) : (
				<div>{getTranslated(notHiring, lang)}</div>
			)}
		</GridList>
	);
};

PositionsList.propTypes = {
	data: PropTypes.array,
	count: PropTypes.number,
	lang: PropTypes.string,
};

export default PositionsList;
