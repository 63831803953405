import React from 'react';
import classnames from 'classnames';
import {getTranslated, lang} from '../../helpers/lang';
import {getDepartmentByKey} from '../../selectors/data';
import Person from '../Person';
import './style.scss';

export default ({dept, right, children}) => {
	const department = getDepartmentByKey(dept);

	if (department) {
		const deptClasses = classnames('gst-department', {
			right: right,
		});

		return (
			<div className={deptClasses}>
				<div className={'gst-department-about'}>
					<h3>{getTranslated(department.title, lang)}</h3>
					{children}
				</div>
				{department.persons.map(person => (
					<div className={'gst-department-person'}>
						<Person email={person.email} key={person.email} />
					</div>
				))}
			</div>
		);
	} else {
		return null;
	}
};
