import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, StaticQuery} from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
import {BgImage as BackgroundImage} from 'gbimage-bridge';
import {getOpenPositionLink} from '../../helpers/links';
import {getTranslated} from '../../helpers/lang';
import {getOpenPositionByID} from '../../selectors/data';

import './style.scss';

const OpenPositionItem = ({id, lang}) => {
	const positionData = getOpenPositionByID(id);

	return (
		<StaticQuery
			query={graphql`
				{
					images: allFile(
						filter: {
							extension: {regex: "/(jpg)|(jpeg)|(png)/"}
							relativeDirectory: {eq: "pages/company/openPositions"}
						}
					) {
						edges {
							node {
								relativePath
								name
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
						}
					}
				}
			`}
			render={data => {
				const image = data.images.edges.find(n => {
					return positionData.imagePath?.includes(n.node.relativePath);
				});

				return (
					<Link
						className="gst-open-position-item"
						to={getOpenPositionLink(positionData.url)}
					>
						<BackgroundImage
							Tag="div"
							className="gst-open-position-item-image"
							image={
								image
									? image.node.childImageSharp.gatsbyImageData
									: ['transparent']
							}
							backgroundColor={`#fff`}
							style={{
								backgroundPosition: 'center center',
								backgroundSize: '100%',
							}}
						/>
						<div className="gst-open-position-item-content">
							<h3>{getTranslated(positionData.title, lang)}</h3>
							<p>{getTranslated(positionData.description, lang)}</p>
						</div>
					</Link>
				);
			}}
		/>
	);
};

OpenPositionItem.propTypes = {
	id: PropTypes.string.isRequired,
	lang: PropTypes.string,
};

export default OpenPositionItem;
