import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {Icon} from '@gisatcz/ptr-atoms';

import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import Content from '../../components/Content';
import Hero from '../../components/Hero';
import PositionsList from '../../components/PositionsList';
import Block from '../../components/Block';
import Department from '../../components/Department';
import {getTranslated, lang} from '../../helpers/lang';

import openPositions from '../../data/openPositions.json';

import './style.scss';
import PageNav from '../../components/PageNav';

const CompanyPage = () => {
	const data = useStaticQuery(graphql`
		{
			contactBackground: file(
				relativePath: {eq: "pages/company/background.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Company" />
			<PageNav>
				<Link to="#team">
					<span>Team</span>
				</Link>
				<Link to="#careers">
					<span>Careers</span>
				</Link>
			</PageNav>
			<Hero
				coloredOverlay
				className="gst-company-hero"
				backgroundImageFluid={
					data.contactBackground.childImageSharp.gatsbyImageData
				}
			>
				<h1>The company</h1>
				<div className="gst-company-header-content">
					<div>
						<p>
							Gisat is the first privately run remote sensing and
							geo-information service company in Czechia. Acting globally in a
							wide range of thematic domains, our mission is to provide our
							clients a complete portfolio of value delivering geo-information
							services based on Earth Observation data. We are committed to
							supporting sustainable living and using Earth's resources by
							enabling everyone to make better decisions about our environment.
						</p>
						<p>
							We rely on a core team of permanent employees, plus an extensive
							network of collaborating experts in specific thematic application
							domains.
						</p>
						<p>
							The company is well established amongst EO service providers and
							has participated in many innovative projects, furthering the use
							of EO data. <br />
							Combining remote sensing, artificial intelligence and big data
							analytics we operate a complete value-chain to unleash the power
							of Earth Observation data at one place. Our online platform
							solutions introduce ready-to-use spatial data exploration tools
							and integrate them into clients’ decision-making mechanisms.
							<br />
							We provide our service and products to a diverse group of clients
							that includes international & financial Institutions, public &
							state authorities and businesses worldwide.
						</p>
					</div>
					<div className="gst-company-header-points">
						<div>
							<Icon icon="calendar" /> <span>est. 1990</span>
						</div>
						<div>
							<Icon icon="map-pin" /> <span>Prague, CZ, EU</span>
						</div>
						<div>
							<Icon icon="settings" /> <span>ISO9001 & ISO14001 certified</span>
						</div>
						<div>
							<Icon icon="settings" /> <span>SME (~ 30 expert personnel)</span>
						</div>
					</div>
				</div>
			</Hero>

			<Block light>
				<Content>
					<h2 id="team">Team</h2>
					<div className="gst-company-departments">
						<Department dept="management">
							<p>
								Managing the day to day operation of the company, overseeing
								ongoing business operations and leading & inspiring the company
								staff.
							</p>
						</Department>
						<Department dept="businessDevelopment" right>
							<p>
								Business development & international collaboration, introduction
								of new services & applications and major projects supervision.
							</p>
						</Department>
						<Department dept="salesMarketing">
							<p>
								Satellite data and software solutions sales & marketing,
								managing relationships with partners and prospects.
							</p>
						</Department>
						<Department dept="officeManagement" right>
							<p>
								Daily management of the company office, support to human
								resources management and project accounting & implementation.
							</p>
						</Department>
						<Department dept="projectManagement">
							<p>
								Managing and supervising project implementation, geoinformation
								service provision and collaboration with partners & suppliers.
							</p>
						</Department>
						<Department dept="gisRS" right>
							<p>
								Development and implementation of novel methods for EO data
								processing & analysis and geospatial data exploration &
								integration.
							</p>
						</Department>
						<Department dept="bigData">
							<p>
								Implementation and operation of cloud based production
								environment and tools dedicated to automated big data processing
								& analysis.
							</p>
						</Department>
						<Department dept="development" right>
							<p>
								Development of web platforms and software tools to support
								exploration and utilisation of provided information products by
								our clients.
							</p>
						</Department>
					</div>
				</Content>
			</Block>

			<Block light accent>
				<Content>
					<h2 id="careers">Careers</h2>
					<p>
						Come work with us. We have cookies. Or, we can get them, if needed.
					</p>
					<h3>Open positions</h3>
					<PositionsList data={openPositions} count={3} lang={lang} />
				</Content>
			</Block>
		</Layout>
	);
};

export default CompanyPage;
